import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from "@angular/core";
import { ConfigService } from "../@vex/services/config.service";
import { Settings } from "luxon";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { NavigationService } from "../@vex/services/navigation.service";
import icLayers from "@iconify/icons-ic/twotone-layers";
import { LayoutService } from "../@vex/services/layout.service";
import { ActivatedRoute } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { SplashScreenService } from "../@vex/services/splash-screen.service";
import { Style, StyleService } from "../@vex/services/style.service";
import { ConfigName } from "../@vex/interfaces/config-name.model";
// import icLayers from "@iconify/icons-ic/twotone-layers";
import icUser from "@iconify/icons-ic/baseline-people-alt";
import { AuthService } from "./pages/auth/authServices";

@Component({
  selector: "vex-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit{
  title = "vex";
  userIsAuthenticated = false;
  constructor(
    private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private authService: AuthService,
  ) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    this.route.queryParamMap
      .pipe(
        map(
          (queryParamMap) =>
            queryParamMap.has("rtl") &&
            coerceBooleanProperty(queryParamMap.get("rtl"))
        )
      )
      .subscribe((isRtl) => {
        this.document.body.dir = isRtl ? "rtl" : "ltr";
        this.configService.updateConfig({
          rtl: isRtl,
        });
      });

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("layout")))
      .subscribe((queryParamMap) =>
        this.configService.setConfig(queryParamMap.get("layout") as ConfigName)
      );

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("style")))
      .subscribe((queryParamMap) =>
        this.styleService.setStyle(queryParamMap.get("style") as Style)
      );

    this.navigationService.items = [ 

      {
      type: 'subheading',
      label: 'Admin',
      children: [
        {
          type: "link",
          label: "Dashboard",
          route: "/dashboard",
          imgIcon: "dashboard"
        },
        {
          type: "link",
          label: "User",
          imgIcon: "face",
          route: "/all-user"
        },
        {
          type: "dropdown",
          label: "Role And Permission",
          icon: icUser,
          imgIcon: "settings",
          children: [
            {
              type: "link",
              label: "Subordinates",
              route: "/administrator",
            },  
            {
              type:  "link",
              label: "Roles",
              route: "/administrator/all-roles",
            }
          ],
        },
        {
          type: "dropdown",
          label: "Game Balancing",
          icon: icUser,
          imgIcon: "chromecast_2",
          children: [
            {
              type: "link",
              label: "Gun Management",
              route: "/gun-management",
            },
            {
              type: "link",
              label: "Character Management",
              route: "/character-management",
            },
            {
              type: "link",
              label: "Attachment Management",
              route: "/attachment-management",
            },
            {
              type: "link",
              label: "Throwable Management",
              route: "/throwable-management",
            },
            {
              type: "link",
              label: "Armour Management",
              route: "/armour-management",
            },
            {
              type: "link",
              label: "Helmet Management",
              route: "/helmet-management",
            },
            {
              type: "link",
              label: "Vehicle Management",
              route: "/vehicle-management",
            }, 
              {
                type: "link",
                label: "Character Skill",
                route: "/character-skill",
              }, 
            // {
            //   type: "link",
            //   label: "Add Ammo",
            //   route: "/ammo",
            // },
            // {
            //   type: "link",
            //   label: "Add Weapon Category",
            //   route: "/weapon-category",
            // },
          ],
        },
        // {
        //   type: "link",
        //   label: "Game Map",
        //   route: "/game-map",
        //   imgIcon: "map"
        // },
        // {
        //   type: "link",
        //   label: "Game Analytics",
        //   route: "/game-analytics",
        //   imgIcon: "monitoring"
        // },
        { 
          type: "dropdown",
          label: "Item Pack",
          imgIcon: "inventory",
          children: [
            {
              type: "link",
              label: "Credit-Packages",
              route: "/pack",
            },
            {
              type: "link",
              label: "Xp-Booster",
              route: "/Xp-Booster",
            },
            {
              type: "link",
              label: "Chest-Box",
              route: "/chest-box",
            },
          ],
        },
        {
          type: "link",
          label: "Premium Store",
          imgIcon: "add_business",
          route: "/store-item"
        },
        {
          type: "link",
          label: "In Game Loot Box",
          imgIcon: "inventory_2",
          route: "/loot-box"
        },
        // {
        //   type: "dropdown",
        //   label: "Vehicles",
        //   imgIcon: "electric_car",
        //   children: [
        //     {
        //       type: "link",
        //       label: "Add / View",
        //       route: "/game-vehicle",
        //     },
        //     {
        //       type: "link",
        //       label: "Category",
        //       route: "game-vehicle-category",
        //     },
        //     {
        //       type: "link",
        //       label: "Vehicle Weapon",
        //       route: "game-vehicle-attachment-weapon",
        //     },
        //   ],
        // },
        // {
        //   type: "link",
        //   label: "Reward",
        //   route: "/reward",
        //   imgIcon: "military_tech",
        // },
        {
          type: "link",
          label: "Tournament",
          route: "/tournament",
          imgIcon: "tour"
        },
        {
          type: "link",
          label: "Event",
          route: "/event",
          imgIcon: "event"
        },
        {
          type: "link",
          label: "Daily-Missions",
          route: "/daily-mission",
          imgIcon: "local_activity"
        },
        {
          type: "link",
          label: "Achievement",
          route: "/achievement",
          imgIcon: "stars"
        },
        {
          type: 'link',
          label: 'Become A Partner Detail',
          route: '/become-a-partner-detail',
          imgIcon:'handshake' 
        }
      ]
    },
 
    {
      type: 'subheading',
        label: 'Web Site CMS',
        children: [  
            {
              type: 'link',
              label: 'Homepage',
              route: '/homepage',
              imgIcon:'home' 
            },
            { 
              type: "dropdown",
              label: "Web Character",
              imgIcon: "group_add",
              children: [
                {
                  type: "link",
                  label: "Character",
                  route: "/add-character",
                },
                {
                  type: "link",
                  label: "Unique Skill",
                  route: "/unique-skill",
                },
              ],
            }, 
            {
              type: 'link',
              label: 'Web Map',
              route: '/map',
              imgIcon:'map' 
            },
            {
              type: 'link',
              label: 'News',
              route: '/news',
              imgIcon:'newspaper' 
            },
            {
              type: 'link',
              label: 'Coming Soon',
              route: '/coming-soon',
              imgIcon:'upcoming' 
            },
            {
              type: 'link',
              label: 'Web Event',
              route: '/website-event',
              imgIcon:'event' 
            },
            {
              type: 'link',
              label: 'Privacy Policy',
              route: '/privacy-policy',
              imgIcon:'lock' 
            },
            {
              type: 'link',
              label: 'Become A Partner Form',
              route: '/become-a-partner',
              imgIcon:'handshake' 
            }

        ]
      } 
    ];
  }
  
  ngOnInit() {
    this.authService.autoAuthUser(); 
    // console.log(this.authService.autoAuthUser);
    
  }

  ngOnChanges(): void{

  }
}
