import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VexRoutes } from 'src/@vex/interfaces/vex-route.interface';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { AuthGuard } from "./pages/auth/auth.guard";
const childrenRoutes: VexRoutes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    // canActivate: [AuthGuard] 
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/auth/profile-tab/profile-tab.module').then(m => m.ProfileTabModule)
  },
  {
    path: 'all-user',
    loadChildren: () => import('./pages/user/all-user/all-user.module').then(m => m.AllUserModule)
  },
  {
    path: 'profile/:id',
    loadChildren: () => import('./pages/user/all-user/user-details/user-details.module').then(m => m.UserDetailsModule),
  },
  {
    path: 'gun-management',
    loadChildren: () => import('./pages/gunmodule/gunmodule.module').then(m => m.GunmoduleModule),
  },

  {
    path: 'character-management',
    loadChildren: () => import('./pages/charactermodule/charactermodule.module').then(m => m.CharactermoduleModule),
  },

  {
    path: 'attachment-management',
    loadChildren: () => import('./pages/attachment-module/attachment-module.module').then(m => m.AttachmentModuleModule),
  },

  {
    path: 'throwable-management',
    loadChildren: () => import('./pages/throwable-module/throwable-module.module').then(m => m.ThrowableModuleModule),
  },
  {
    path: 'armour-management',
    loadChildren: () => import('./pages/armour-module/armour-module.module').then(m => m.ArmourModuleModule),
  },

  {
    path: 'helmet-management',
    loadChildren: () => import('./pages/helmet-module/helmet-module.module').then(m => m.HelmetModuleModule),
  },
  {
    path: 'vehicle-management',
    loadChildren: () => import('./pages/vehicle-module/vehicle-module.module').then(m => m.VehicleModuleModule),
  },
  {
    path: "game-weapon",
    loadChildren: () => import('./pages/game-weapon/Weapons/game-weapon.module').then(m => m.GameWeaponModule)
  },
  {
    path: "game-map",
    loadChildren: () => import('./pages/game-map/game-map.module').then(m => m.GameMapModule)
  },
  {
    path: "game-item",
    loadChildren: () => import('./pages/game-item/game-item.module').then(m => m.GameItemModule)
  },
  {
    path: "game-item-category",
    loadChildren: () => import('./pages/game-item/game-item-category/game-item-category.module').then(m => m.GameItemCategoryModule)
  },
  {
    path: "game-vehicle",
    loadChildren: () => import('./pages/game-vehicle/game-vehicle.module').then(m => m.GameVehicleModule)
  },
  {
    path: "game-vehicle-attachment-weapon",
    loadChildren: () => import('./pages/game-vehicle/attachment-weapon/attachment-weapon.module').then(m => m.AttachmentWeaponModule)
  },
  {
    path: "game-vehicle-category",
    loadChildren: () => import('./pages/game-vehicle/vehicle-category/vehicle-category.module').then(m => m.VehicleCategoryModule)
  },
  {
    path: "ammo",
    loadChildren: () => import('./pages/game-weapon/ammo/ammo.module').then(m => m.AmmoModule)
  },
  {
    path: "weapon-category",
    loadChildren: () => import('./pages/game-weapon/weapon-category/weapon-category.module').then(m => m.WeaponCategoryModule)
  },
  {
    path: 'event',
    loadChildren: () => import('./pages/game-event/game-event.module').then(m => m.GameEventModule)
  },
  {
    path: 'daily-mission',
    loadChildren: () => import('./pages/daily-mission/daily-mission-routing.module').then(m => m.DailyMissionRoutingModule)
  },
  {
    path: 'achievement',
    loadChildren: () => import('./pages/achivement/achivement.module').then(m => m.AchivementModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'administrator',
    loadChildren: () => import('./pages/role-and-permissions/role-and-permissions.module').then(m => m.RoleAndPermissionsModule)
  },
  {
    path: 'store-item',
    loadChildren: () => import('./pages/store/store.module').then(m => m.StoreModule)
  },
  {
    path: 'homepage',
    loadChildren: () => import('./pages/homepage/homepage.module').then(m => m.HomepageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then(m => m.MapModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule)
  },
  {
    path: 'website-event',
    loadChildren: () => import('./pages/website-event/website-event.module').then(m => m.WebsiteEventModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'add-character',
    loadChildren: () => import('./pages/add-character/add-character.module').then(m => m.AddCharacterModule)
  },
  {
    path: 'unique-skill',
    loadChildren: () => import('./pages/unique-skill/unique-skill.module').then(m => m.UniqueSkillModule)
  },
  {
    path: 'character-skill',
    loadChildren: () => import('./pages/character-skill/character-skill.module').then(m => m.CharacterSkillModule)
  },
  {
    path: 'become-a-partner',
    loadChildren: () => import('./pages/become-a-partner/become-a-partner.module').then(m => m.BecomeAPartnerModule)
  },
  {
    path: 'tournament',
    loadChildren: () => import('./pages/tournament/tournament.module').then(m => m.TournamentModule)
  },
  {
    path: 'become-a-partner-detail',
    loadChildren: () => import('./pages/beacome-a-partner-detail/beacome-a-partner-detail.module').then(m => m.BeacomeAPartnerDetailModule)
  },
  {
    path: 'pack',
    loadChildren: () => import('./pages/Packages/credit-package/credit-package.module').then(m => m.CreditPackageModule)
  },
  {
    path: 'Xp-Booster',
    loadChildren: () => import('./pages/Packages/xp-booster/xp-booster.module').then(m => m.XpBoosterModule)
  },
  {
    path: 'chest-box',
    loadChildren: () => import('./pages/Packages/chest-box/chest-box.module').then(m => m.ChestBoxModule)
  },
  {
    path: 'loot-box',
    loadChildren: () => import('./pages/loot-box/loot-box.module').then(m => m.LootBoxModule)
  }

]

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '',
    component: CustomLayoutComponent,
    children: childrenRoutes,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/check-email/check-email.module').then(m => m.CheckEmailModule)
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'error-500',
    loadChildren: () => import('./pages/505/505.module').then(m => m.Error505Module)
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled',
    useHash: true,
    // enableTracing: true
  })],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
