import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
 import { Subscription } from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from "./error.service";

@Component({
  templateUrl: "./error.component.html",
  selector: "app-error",
  styleUrls: ["./error.component.css"]
})
export class ErrorComponent {
  data1: { message: string };
  private errorSub: Subscription;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string }, private toastr: ToastrService, private errorService: ErrorService) {
    // this.toastr.error('failed!', data.message, {
    //   timeOut: 3000,
    //   closeButton: true
    // });
    console.log(data.message)
  }
  // constructor(private errorService: ErrorService) {}
  
  ngOnInit() {
    this.errorSub = this.errorService.getErrorListener().subscribe(message => {
      this.data1 = { message: message };
    });
  }

  onHandleError() {
    this.errorService.handleError();
  }

  ngOnDestroy() {
    this.errorSub.unsubscribe();
  }
}
