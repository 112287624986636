import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

import { ToastrService } from 'ngx-toastr';

import { environment } from "../../../environments/environment";
import { AuthData } from "./auth-data.model";

const BACKEND_URL = environment.apiUrl + "/admin/";

@Injectable({ providedIn: "root" })
export class AuthService {
  private isAuthenticated = false;
  private token: string;
  private tokenTimer: any;
  private userId: string;
  private username: string;
  private permission: any;
  private authStatusListener = new Subject<boolean>();
  isLoading: boolean;
  public isLoadingsubject = new Subject<boolean>();
  // private forgotStatusListener = new Subject<boolean>();
  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService) {}

  getToken() {
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getUserId() {
    return this.userId;
  }

  getuserName() {
    return this.username;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }


  login(email: string, password: string) {
    const authData: AuthData = { email: email, password: password };
    this.http
      .post<{ token: string; expiresIn: number; userId: string, username: string, role: string,permission:any }>(
        BACKEND_URL + "login",
        authData
      )
      .subscribe(
        response => {
         
          const token = response.token; 
          this.token = token;
          if (token) {
            localStorage.setItem('permission',JSON.stringify(response.permission));
            const expiresInDuration = response.expiresIn;
            this.setAuthTimer(expiresInDuration);
            this.isAuthenticated = true;
            this.userId = response.userId;
            this.username = response.username;
            this.authStatusListener.next(true);
            const now = new Date();
            const expirationDate = new Date(
              now.getTime() + expiresInDuration * 1000
            );
            console.log(expirationDate);
            this.saveAuthData(token, expirationDate, this.userId, this.username);
              this.toastr.success('login successfully', response.username, {
                timeOut: 1500,
                closeButton: true
              });
              this.router.navigate(['dashboard']);
          }
        },
        error => {
          this.authStatusListener.next(false);
        }
      );
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    // console.log(authInformation)
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.userId = authInformation.userId;
      this.username = authInformation.username;
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
     }
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.userId = null;
    this.username = null,
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(["/"]);
    setTimeout(() =>{
      this.toastr.info('is logged out','', {
        timeOut: 2000,
      });
    }, 500);
   
  }

  private setAuthTimer(duration: number) {
    console.log("Setting timer: " + duration);
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(token: string, expirationDate: Date, userId: string, username: string) {
 
    localStorage.setItem("token", token);
    localStorage.setItem("expiration", expirationDate.toISOString());
    localStorage.setItem("userId", userId);
    localStorage.setItem("username", username); 
  } 
  private clearAuthData() {
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    localStorage.removeItem("userId");
    localStorage.removeItem("username");
    localStorage.removeItem("permission");
  }

  private getAuthData() {
    const token = localStorage.getItem("token");
    const expirationDate = localStorage.getItem("expiration");
    const userId = localStorage.getItem("userId");
    const username = localStorage.getItem("username") 
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
      username: username, 
    };
  }


  forgotPassword(email: string) {
    const Email = {  email: email }
    this.http
      .post<{ message: string, status: string }> (
        BACKEND_URL + "forgotPassword",
        Email
      )
      .subscribe(responce => {
        console.log(responce);
        if(responce.status = "success"){
          // this.isLoading = false;
          this.isLoadingsubject.next(true);
        }
        setTimeout(() =>{
          this.toastr.info('Successfully sended password reset link on your email👍.', "Reset Link", {
            timeOut: 6000,
            closeButton: true,
          });
        }, 400);
      },
      error => {
        this.authStatusListener.next(false);
      }
      )
  }

  resetPassword(password: string, passwordConfirm: string, token: string) {
     const resetData = { password: password, passwordConfirm: passwordConfirm };
     this.http
     .patch<{data}> (
       BACKEND_URL + 'resetPassword' + '/' + token,
       resetData
     )
     .subscribe(responce => {
      this.toastr.success('Your password is successfully reset👍.', "Successfully", {
        timeOut: 4000,
        closeButton: true,
      });
      setTimeout(() =>{
        this.router.navigate(['login']);
      }, 300);
      
     })
  }


}


