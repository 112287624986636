import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Admins } from './model';

const BACKEND_URL = `${environment.apiUrl}/admin`;

@Injectable({
    providedIn: 'root'
})
export class AdminProfileService {

    activeData = new BehaviorSubject<boolean | null>(null);
    onActive = this.activeData.asObservable();
    private subordinate: Admins[] = [];
    private subordinateUpdate = new Subject<{ subordinate: Admins[] }>();
    constructor(private http: HttpClient, private router: Router, private toastr: ToastrService) { }
    getAll() {
        this.http.get<{ message: string; data: any }>(`${BACKEND_URL}/getAll/subordinate`).pipe(
            map(Data => {
                return {
                    subordinate: Data.data.map(data => {
                        return {
                            _id: data._id,
                            name: data.name,
                            email: data.email,
                            profile_image: data.profile_image,
                            role: data.role,
                            address: data.address,
                            phone: data.phone,
                            active: data.active
                        }
                    }),
                };
            })
        ).subscribe(transformedData => {
            this.subordinate = transformedData.subordinate;
            this.subordinateUpdate.next({
                subordinate: [...this.subordinate]
            });
        });
    }
    getUpdateListener() {
        return this.subordinateUpdate.asObservable();
    }
    getByID(id: string) {
        return this.http.get<any>(BACKEND_URL + '/get-profile/' + id);
    }

    updateProfilePic(
        profile_image: File | string,
    ) {
        let Data = new FormData();

        Data.append('profile_image', profile_image);

        this.http
            .put(BACKEND_URL + '/update/profile', Data)
            .subscribe(response => {
                this.setactiveData = true;
                if (response) {
                    setTimeout(() => {
                        this.toastr.success('Done!', " Profile pic update successfully.", {
                            timeOut: 2500,
                            closeButton: true
                        });
                    }, 400);
                }
            });
    }

    updatePassword(
        passwordCurrent: string,
        password: string,
        passwordConfirm: string,
    ) {
        let Data = {
            passwordCurrent: passwordCurrent,
            password: password,
            passwordConfirm: passwordConfirm
        }
        console.log(Data)
        this.http
            .patch(BACKEND_URL + '/updateMyPassword', Data)
            .subscribe(response => {
                if (response) {
                    setTimeout(() => {
                        this.toastr.success('Done!', "Password Change Successfully.", {
                            timeOut: 2500,
                            closeButton: true
                        });
                    }, 400);
                }
            });
    }

    set setactiveData(active: boolean) {
        this.activeData.next(active);
    }


}
