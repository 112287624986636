import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { throwError } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ErrorComponent } from "./pages/error/error.component";
import { ErrorService } from "./pages/error/error.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private dialog: MatDialog,
    private errorService: ErrorService,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap((event) => {
        // Handle successful responses here if needed
        if (event instanceof HttpResponse) {
          // Do something with the response
        }
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = "An unknown error occurred!";

        if (error.error?.message) {
          errorMessage = error.error.message;
        }

        this.toastr.error(errorMessage, "", {
          timeOut: 3000,
          closeButton: true,
        });

        return throwError(error);

        // if (error.status !== 0 && error.status !== 500) {
        //   if (error.error?.message) {
        //     errorMessage = error.error.message;
        //   } else {
        //     errorMessage = error.statusText;
        //   }
        //   this.toastr.error(errorMessage, "", {
        //     timeOut: 3000,
        //     closeButton: true,
        //   });
        // } else {
        //   this.toastr.error(errorMessage, "", {
        //     timeOut: 3000,
        //     closeButton: true,
        //   });
        //   this.router.navigate(["error-500"]);
        // }
        // return throwError(error);
      })
    );
  }
}
