// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: "http://localhost:8082/api/v1",
  // imageURL: "http://localhost:8082/api/images/",

  apiUrl : "https://adimincms.bountyhunter.com/api/v1",
  imageURL: "https://adimincms.bountyhunter.com/api/images/",

  //  apiUrl:"http://52.54.184.210:8082/api/v1",

  // google_key: "AIzaSyBPk-M5i-qtu-8RVe5J8Tuw2uTpJzvHq5M"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
